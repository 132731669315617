import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import placeName from './components/subcomponents/PlaceName.vue'
import Events from './components/subcomponents/Events.vue'
import navigation from './components/subcomponents/Navigation.vue'
import personLink from './components/subcomponents/PersonLink.vue'
import tabs from './components/subcomponents/Tabs.vue'
import Cookies from './components/Cookies.vue'
import { createHead, Head } from '@vueuse/head'

const app = createApp(App)
app.config.globalProperties.apiUrl = 'https://api.slektforskning.se/' + process.env.VUE_APP_ID
app.config.globalProperties.websiteTitle = process.env.VUE_APP_TITLE
app.config.globalProperties.alphabet = [...'abcdefghijklmnopqrstuvwxyzåäö']
app.config.globalProperties.treeWidths = app.config.globalProperties.treeData = []

app.component('Head', Head)
app.component('navigation', navigation)
app.component('person-link', personLink)
app.component('tabs', tabs)
app.component('events', Events)
app.component('place-name', placeName)
app.component('cookies', Cookies)
app.use(createHead()).use(router).mount('body')
