import { createRouter, createWebHistory } from 'vue-router'
import Person from '../components/Person'
import Place from '../components/Place'
import Cookies from '../components/Cookies'

const routes = [
  { path: '/kontakt', component: () => import(/* webpackChunkName: "lazy" */ '../components/Contact.vue') },
  { path: '/:person(.*Antavla)', component: () => import(/* webpackChunkName: "lazy" */ '../components/PersonTree.vue') },
  { path: '/:person(.*Media)', component: Person, meta: { activeTab: 'Media' } },
  { path: '/:person(.*Karta)', component: Place },
  { path: '/:person(.*-[a-z0-9]*)', component: Person, meta: { activeTab: 'Person' } },
  { path: '/platser/:place(.*)?', component: Place },
  { path: '/personer', component: () => import(/* webpackChunkName: "lazy" */ '../components/Search.vue') },
  { path: '/', component: Place },
  { path: '/om', component: () => import(/* webpackChunkName: "lazy" */ '../components/Static.vue'), meta: { title: 'Om', description: 'Om min forskning' } },
  { path: '/nyheter', component: () => import(/* webpackChunkName: "lazy" */ '../components/Static.vue'), meta: { title: 'Nyheter', description: 'Nyheter i min forskning' } }
]
if (process.env.VUE_APP_GTM) {
  routes.push({ path: '/cookies', component: Cookies })
}
if (process.env.VUE_APP_ID === '1') {
  routes.push({ path: '/brandt', component: () => import(/* webpackChunkName: "lazy" */ '../components/Static.vue'), meta: { title: 'Brandtsläkten', description: 'Information om släkten Brandt från Tåstarp, Skåne' } })
} else if (process.env.VUE_APP_ID === '2') {
  routes.push({ path: '/dombocker', component: () => import(/* webpackChunkName: "lazy" */ '../components/Static.vue'), meta: { title: 'Domböcker', description: 'Domböcker' } })
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
