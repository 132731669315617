<template>
  <div class="cookies popup" v-if="forceShow||(show&&!content)">
    <div>
      <div v-if="!showMoreContent">Den här webbplatsen använder cookies för att samla in anonym besöksstatistik.</div>
      <div v-if="showMoreContent" v-html="showMoreContent"></div>
      <button class="btn" @click="setCookie('granted')">Acceptera</button>
      <button class="btn" @click="setCookie('denied')">Neka</button>
      <div><a v-if="!showMoreContent" href="/cookies" @click.prevent="showMore()">Läs cookiepolicy.</a></div>
    </div>
  </div>
  <article v-if="content" class="cookies">
    <div v-html="content"></div>
    <div class="settings">
      <button class="btn" @click="forceShow=true">Ändra cookieinställningar</button>
    </div>
  </article>
</template>

<style lang="scss">
.cookies {
  button {
    background-color: $MASTER_COLOR;
    color: #fff;
    border-radius: 1.5rem;
    padding: 10px 1em;
    min-width: 100px;
    font-size: 1.1rem;
    margin:10px
  }
  text-align:center;
  top: 3.2rem
}
.popup {
  a {
    text-decoration: underline
  }
  > div {
    margin: auto;
    max-height: 50%;
    position:relative;
    background: #fff;
    top: 25%;
    max-width: 30rem;
    border-radius: 5px;
    padding: 15px;
    text-align: center;
    overflow:auto
  }
  background-color: rgba(0,0,0,.7);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 9999
}
</style>

<script>
export default {
  data () {
    return {
      show: document.cookie.indexOf('slektcookies') < 0 && process.env.VUE_APP_GTM,
      showMoreContent: null,
      content: null,
      forceShow: false
    }
  },
  methods: {
    showMore () {
      fetch('/assets/cookies.html').then(response => response.text()).then(content => (this.showMoreContent = content))
    },
    setCookie (cookieValue) {
      document.cookie = `slektcookies=${cookieValue}; expires=${new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365).toGMTString()};`
      // eslint-disable-next-line no-undef
      gtag('consent', 'update', {
        ad_storage: cookieValue,
        analytics_storage: cookieValue
      })
      this.show = this.forceShow = false
    }
  },
  beforeRouteEnter (to, _from, next) {
    next(vm => {
      if (to.path === '/cookies') {
        vm.description = 'Cookiepolicy för webbsidan'
        vm.title = 'Cookiepolicy'
        fetch('/assets/cookies.html').then(response => response.text()).then(content => (vm.content = content))
      }
    })
  }
}
</script>
