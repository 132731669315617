export default {
  methods: {
    getName (person, withTitle, showPeriod, asHtml) {
      let name = (withTitle && person.Title ? person.Title + ' ' : '') +
        [person.FirstName, person.LastName].join(' ').trim()

      if (name.length === 0) {
        name = 'okänd'
      }

      if (asHtml) {
        name = this.getNameAsHhtml(name)
      }

      if (showPeriod) {
        const birth = this.getEventValue(person, 'Birth')
        const death = this.getEventValue(person, 'Death')
        if (birth || death) {
          name += ' (' + birth + ' - ' + death + ')'
        }
      }
      return name
    },
    getEventValue (person, eventName) {
      const typeId = person[eventName + 'TypeId']
      return (['', '>', '<', '', '', '', '~'][typeId] || '') + ((typeId === 3) ? person[eventName + 'Year2'] : (person[eventName + 'Year1'] || person[eventName + 'String'] || ''))
    },
    pReverse (place) {
      return place.split(', ').reverse().join(', ')
    },
    getOccupation (person) {
      return [person.Occupation ? person.Occupation + '.' : '', person.Age].join(' ')
    },
    getNameAsHhtml (name) {
      return name ? name.replace(/([\w-]+\*)/, '<strong><u>$1</u></strong>').replace('*', '').replace(' ', '&nbsp') : name
    },
    getPlaceType (placeType) {
      if (!placeType) return ''
      return ['',
        'By/samhälle',
        'Församling/socken/kyrka',
        'Domsaga',
        'Gård/herrgård/hemman',
        'Fängelse/anstalt',
        'Härad/tingslag',
        'Kommun',
        'Gata/kvarter/stadsdel/byggnad',
        'Stat',
        'Land',
        'Landskap',
        'Provins/region',
        'Län/fylke/amt/county',
        'Militärförläggning /militärboställe',
        'Pastorat',
        'Rote',
        'Slott',
        'Bruk/fabrik/kvarn/gruva',
        'Universitet/läroverk',
        'Stad',
        'Stift',
        'Gods/säteri',
        'Sjö/vattendrag',
        'Stuga/torp',
        'Sjukhus/behandlingshem',
        'Hamn',
        'Kyrkogård/gravplats'][parseInt(placeType)]
    }
  }
}
