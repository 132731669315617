<template>
  <Head>
    <title>{{title}}</title>
    <meta name="description" :content="description"/>
  </Head>
  <tabs v-if="!startPage" :activeTab="activeTab"/>
  <article :class="{keeplist: !person || !mapsKey, homepage:startPage}">
    <div id="map" :style="{left: !startPage ? '25rem':'28rem'}"></div>
    <div v-if="startPage" v-html="content"></div>
    <template v-if="startPage&&content">
      <b>Födda dagens datum (urval)</b>
      <person-link v-for="person in birthdays" :key="person" :person="person" />
    </template>
    <div class="list" :class="{nomap: !mapsKey}" v-else-if="place">
      <h1>{{pReverse(place.PlaceName)}}</h1>
      <i>{{getPlaceType(place.PlaceType)}}</i>
      <ul>
        <li v-for="person in place.persons" :key="person">
          <person-link :person="person" />
        </li>
      </ul>
    </div>
    <div class="list" v-else-if="person">
      <h1><person-link :person="person" :showPeriod="true" :withLink="false" :withTitle="false" /></h1>
      <ul class="events">
        <events :events="person.Events" @eventEnter="eventEnter" @eventLeave="stopBounce()" :forPerson="false"/>
      </ul>
    </div>
    <div class="list" :class="{nomap: !mapsKey}" v-else-if="!startPage && places">
      <h1 v-if="!searchResult">Platser</h1>
      <h1 v-else>Sökresultat för '{{q}}'</h1>
      <p v-if="places.length==0">Inga träffar...</p>
      <ul class="letters" v-if="!searchResult">
        <li v-for="letter in alphabet" :key="letter">
          <router-link :to="'/platser/' + letter">{{letter}}</router-link>
        </li>
      </ul>
      <ul class="places">
        <li v-for="place in places" :key="place" :class="{marker:place.Longitude}" @mouseenter="startBounce(place.Latitude, place.Longitude)" @mouseleave="stopBounce()">
          <place-name :placeName="place.PlaceName"/>
        </li>
      </ul>
    </div>
    <div v-else-if="error">
      <h1>Platsen saknas...</h1>
    </div>
  </article>
</template>
<script>
import { getCurrentInstance } from '@vue/runtime-core'
import RenderPersonTitle from '../mixins/renderPersonTitle'
import Fetch from '../mixins/fetch'
let markers = []
export default {
  mixins: [
    RenderPersonTitle,
    Fetch
  ],
  beforeRouteEnter (to, _from, next) {
    next(vm => {
      vm.getPlace(to)
    })
  },
  beforeRouteUpdate (to, _from, next) {
    this.getPlace(to)
    next()
  },
  methods: {
    getPlace (to) {
      this.startPage = to.href === '/'
      this.error = false
      this.gmapPromise.then(() => {
        this.initMap()
        if (this.startPage) {
          this.initStart()
        } else if (to.params.person) {
          this.initPerson(to)
        } else if (to.params.place) {
          this.initPlace(to)
        } else if (to.query.q) {
          this.initSearch(to)
        } else {
          this.fetchByLetter()
        }
      })
    },
    eventEnter (event) {
      this.startBounce(event.PlaceLatitude, event.PlaceLongitude)
    },
    startBounce (latitude, longitude) {
      if (latitude && longitude && this.mapsKey) {
        // eslint-disable-next-line eqeqeq
        markers.find(i => i.getPosition().lat() == latitude && i.getPosition().lng() == longitude).setAnimation(window.google.maps.Animation.BOUNCE)
      }
    },
    stopBounce () {
      markers.forEach(x => { if (this.mapsKey && x.getAnimation() !== null) { x.setAnimation(null) } })
    },
    initStart () {
      this.title = this.websiteTitle
      this.description = process.env.VUE_APP_DESCRIPTION
      this.place = this.person = this.searchresult = null
      if (this.mapsKey) {
        this.map.setCenter({ lat: 57.220072, lng: 13.914963 })
        this.map.setZoom(7)
      }
      fetch('/assets/start.html').then(response => response.text()).then(content => (this.content = content))
      this.fetchData('/persons/?birthday').then(data => { this.birthdays = data })
      this.fetchData('/places/ancestors2').then(places => {
        this.places = places
        if (this.mapsKey) {
          places.forEach(place => { this.addCoordinates(place.Latitude, place.Longitude, place.PlaceName, place.PlaceType) })
        }
      })
    },
    initMap () {
      if (this.mapsKey) {
        markers.forEach(x => x.setMap(null))
        markers = []
        const gmap = window.google.maps
        this.map = this.map || new gmap.Map(document.getElementById('map'), { maxZoom: 14 })
        this.bounds = new gmap.LatLngBounds()
      }
    },
    initPerson (to) {
      this.fetchData('/persons/' + this.pPop(to.params.person)).then(person => {
        this.places = this.place = this.content = this.searchresult = null
        this.person = person
        this.title = 'Karta för ' + this.getName(this.person, true, true)
        this.description = this.title + this.getOccupation(this.person)
        this.title += ' - ' + this.websiteTitle
        if (this.person.Events && this.mapsKey) {
          this.person.Events.forEach(event => { this.addCoordinates(event.PlaceLatitude, event.PlaceLongitude, event.Name + ' ' + event.PlaceName, event.PlaceType) })
          this.map.fitBounds(this.bounds)
        }
      })
    },
    initPlace (to) {
      if (to.params.place.length === 1) {
        this.letter = to.params.place
        this.fetchByLetter()
      } else {
        fetch(this.apiUrl + '/places/' + to.params.place).then(response => response.json()).then(place => {
          this.person = this.places = this.content = this.searchresult = null
          this.place = place
          this.title = this.pReverse(this.place.PlaceName)
          this.description = 'Personer som vistats i ' + this.title + ' (' + this.getPlaceType(place.PlaceType) + ')'
          this.title += ' - ' + this.websiteTitle
          if (this.mapsKey) {
            this.addCoordinates(place.Latitude, place.Longitude, place.PlaceName, place.PlaceType)
            this.map.fitBounds(this.bounds)
          }
        }).catch(() => {
          this.map.setCenter({ lat: 57.220072, lng: 13.914963 })
          this.map.setZoom(7)
          this.error = true
        })
      }
    },
    initSearch (to) {
      this.fetchData('/search?q=' + to.query.q + '&l=100000&page=1&sort=').then(data => {
        this.searchResult = data
        this.places = data.places || []
        this.q = to.query.q
        this.title = 'Sökresultat för ' + to.query.q
        this.description = this.title
        this.title += ' - ' + this.websiteTitle
        if (this.mapsKey && this.places) {
          this.places.forEach(place => { this.addCoordinates(place.Latitude, place.Longitude, place.PlaceName, place.PlaceType) })
          this.map.fitBounds(this.bounds)
        }
      })
    },
    fetchByLetter () {
      this.title = 'Platser ' + this.letter.toUpperCase() + ' - ' + this.websiteTitle
      this.description = 'Platser som börjar med bokstaven ' + this.letter.toUpperCase() + ' i min forskning.'
      this.fetchData('/places?letter=' + this.letter).then(places => {
        this.place = this.person = this.content = this.searchresult = null
        this.places = places
        if (this.mapsKey) {
          places.forEach(place => { this.addCoordinates(place.Latitude, place.Longitude, place.PlaceName, place.PlaceType) })
          this.map.fitBounds(this.bounds)
        }
      })
    },
    addCoordinates (latitude, longitude, name, placeType) {
      if (latitude && longitude) {
        const gmap = window.google.maps
        const href = '/platser/' + name.replace(/\s\([A-Z]{1,2}\)/ig, '').replace(/,\s?/ig, '/').replace(/\s(nr\s[0-9]+)$/, '/$1')
        const latLng = new gmap.LatLng(latitude.replace(/[A-Z]/, '-'), longitude.replace(/[A-Z]/, '-'))
        const marker = new gmap.Marker({ position: latLng, map: this.map, icon: '/img/marker_' + process.env.VUE_APP_NAME + '.png' })
        const infoWindow = new gmap.InfoWindow({ content: name + '<br/><i>' + this.getPlaceType(placeType) + '</i>' })
        gmap.event.addListener(marker, 'mouseover', function () { infoWindow.open(this.map, marker) })
        gmap.event.addListener(marker, 'mouseout', function () { infoWindow.close() })
        gmap.event.addListener(marker, 'click', function () { location.href = href })
        markers.push(marker)
        this.bounds.extend(latLng)
      }
    }
  },
  data () {
    return {
      startPage: null,
      person: null,
      place: null,
      places: null,
      birthdays: null,
      letter: 'a',
      bounds: null,
      map: null,
      title: this.websiteTitle,
      description: '',
      content: null,
      searchResult: null,
      q: null,
      activeTab: 'Map',
      error: false
    }
  },
  setup () {
    const mapsKey = process.env.VUE_APP_GOOGE_MAPS_KEY
    const internalInstance = getCurrentInstance()
    if (!internalInstance.appContext.config.globalProperties.gmapPromise) {
      internalInstance.appContext.config.globalProperties.gmapPromise = new Promise((resolve) => {
        if (mapsKey) {
          window.__onGapiLoaded = () => { resolve(window.gapi) }
          const googleMapScript = document.createElement('SCRIPT')
          googleMapScript.src = 'https://maps.googleapis.com/maps/api/js?key=' + mapsKey + '&callback=__onGapiLoaded'
          googleMapScript.async = googleMapScript.defer = true
          document.head.appendChild(googleMapScript)
        } else {
          resolve(window.gapi)
        }
      })
    }
    return {
      mapsKey
    }
  }
}
</script>

<style lang="scss">
.gm-style iframe + div { border:none!important; }
.homepage {
  a {
      font-weight: bold;
      display:block
  }
  > div > div {
      margin-bottom:1.3rem
  }
  #map {
   margin-bottom: 0px;
  }
  top: 3.4rem
}
.nomap {
  h1 {
    width:auto !important;
  }
  overflow:inherit !important;
  width: inherit !important;
  height:inherit !important;
}
.letters {
  left: 0px;
  margin-top: 0.4rem;
  text-transform: uppercase;
  position:absolute
}
.keeplist:not(.homepage) {
  padding-top: 0;
}
@media (max-width: 767px) {
  .keeplist {
    #map {
      display:none
    }
    .list {
      display:block;
      width:100% !important;
      height:auto !important
    }
  }
  #map {
      left: 0px !important;
    }
  .list {
    display:none;
  }
}

#map {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px
}
.places {
  margin-left: 1.5rem
}
.list {
  a {
    margin: 5px
  }
  i {
    display:block;
  }
  li {
    margin-top: 5px;
    padding-left: 10px;
    &.marker {
        cursor:pointer;
        background: url($MARKER) 10px 18px no-repeat;
        background-size: 10px 18px;
        background-position: left;
      }
  }
  ul.events {
    font-size: 1rem;
    li {
      margin-top: 0px;
      padding-left: 30px;
      padding-top: 10px;
      &.marker {
        background-size: 22px 36px
      }
      a {
        margin: 0px
      }
    }
  }
  h1 {
    position: sticky;
    background-color: white;
    width:21.9rem;
    top: 0px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  font-size: 0.8rem;
  overflow: auto;
  height: calc(100vh - 5.8rem);
  width: 24rem
}
</style>
