<template>
    <table v-if="forPerson">
      <tr v-for="event in events" :key="event">
        <td>{{event.Name}}</td>
        <td>
          {{event.DateString}}
          <place-name :placeName="event.PlaceName" :individualLinks="true"/>
          <sup v-if="event.Sources">
            <template v-for="source in event.Sources" :key="source">
              {{renderSource(source, $parent.person)}})
            </template>
          </sup>
        </td>
        <td v-html="formatNote(event.Note)"></td>
      </tr>
    </table>
    <template v-else v-for="event in events" :key="event">
      <li class="marker" v-if="event.PlaceLatitude || event.PlaceLongitude" @mouseenter="$emit('eventEnter', event)" @mouseleave="$emit('eventLeave')">
          <div>{{event.Name}} {{event.DateString}}</div>
          <place-name :placeName="event.PlaceName" :individualLinks="true" />
      </li>
    </template>
</template>

<style lang="scss">
table {
    margin-top: 1rem
}
tr {
    margin-bottom:10px;
    vertical-align: baseline
}
td {
    &:first-child {
        min-width:7rem;
        font-weight: bold
    }
    &:last-child {
        width:50%
    }
}

@media (max-width: 992px) {
    table, table *:not(a) {
        display: block
    }
    td:last-child {
        font-style:italic;
        width:100%;
        font-size:0.9em
    }
}
</style>

<script>
import RenderSource from '../../mixins/renderSource'
export default {
  emits: ['eventEnter', 'eventLeave'],
  mixins: [RenderSource],
  props: {
    events: null,
    forPerson: {
      default: true,
      type: Boolean
    }
  }
}
</script>
